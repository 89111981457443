<template>
  <div>
    <b-row align-h="end">
      <b-col cols="auto">
        <b-button
          variant="primary"
          @click="$refs.modalTemplate.show(), novoTemplate=true, clearCamposTemplate()"
        >
          <feather-icon
            icon="PlusIcon"
          />
          Template WhatsApp
        </b-button>
      </b-col>
    </b-row>
    <!-- Criando o modal -->
    <b-modal
      ref="modalTemplate"
      size="lg"
      hide-footer
    >
      <modal-update-criate-template
        :template="template"
        :novo-template="novoTemplate"
        @clear="clearCamposTemplate()"
        @templates="getTemplates()"
        @hide="$refs.modalTemplate.hide()"
      />
    </b-modal>
    <!-- </b-modal> -->
    <b-row class="mt-2">
      <b-col>
        <b-card>
          <b-row align-h="center">
            <b-col cols="8">
              <b-form-input
                v-model="tableTemplateWhatsApp.filter"
                type="search"
                placeholder="Pesquisar"
                class="mb-2"
                size="sm"
              />
            </b-col>
          </b-row>
          <b-table
            hover
            bordered
            striped
            responsive
            :filter="tableTemplateWhatsApp.filter"
            :current-page="tableTemplateWhatsApp.currentPage"
            :per-page="tableTemplateWhatsApp.perPage"
            :fields="tableTemplateWhatsApp.fields"
            :items="tableTemplateWhatsApp.items"
          >
            <template #cell(mensagem)="data">
              <b-col class="vaiOUracha">
                {{ data.item.mensagem }}
              </b-col>
            </template>
            <template #cell(status)="data">
              {{ data.item.status ? 'Ativo' : 'Inativo' }}
            </template>
            <template #cell(modo_executivo)="data">
              {{ data.item.modo_executivo ? 'Ativo' : 'Inativo' }}
            </template>
            <template #cell(detalhes)="data">
              <b-row align-h="between">
                <b-col>
                  <feather-icon
                    class="color-icon"
                    icon="EditIcon"
                    @click="editarTemplate(data.item)"
                  />
                </b-col>
                <b-col>
                  <feather-icon
                    class="trash-icon"
                    icon="TrashIcon"
                    @click="deleteFornecedor(data.item)"
                  />
                </b-col>
              </b-row>
            </template>
          </b-table>
          <b-pagination
            v-model="tableTemplateWhatsApp.currentPage"
            :total-rows="tableTemplateWhatsApp.totalRows"
            :per-page="tableTemplateWhatsApp.perPage"
            align="center"
            size="sm"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>

import {
  BRow,
  BCol,
  BButton,
  BCard,
  BTable,
  BPagination,
  VBModal,
  BModal,
  BFormInput,
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import axios from '@/../axios-auth'
import modalUpdateCriateTemplate from './components/modalUpdateCriateTemplate.vue'

export default {
  components: {
    FeatherIcon,
    BRow,
    BCol,
    BButton,
    BCard,
    BModal,
    BTable,
    BPagination,
    BFormInput,
    modalUpdateCriateTemplate,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      novoTemplate: true,
      tableTemplateWhatsApp: {
        filter: '',
        fields: [
          'id',
          'titulo',
          'mensagem',
          'status',
          { key: 'modo_executivo', label: 'Executivo', class: 'text-center' },
          { key: 'detalhes', label: 'ações', class: 'text-center' },
        ],
        items: [],
        totalRows: 1,
        perPage: 10,
        currentPage: 1,
      },
      template: {
        titulo: '',
        mensagem: '',
        status: true,
        modo_executivo: false,
      },
    }
  },
  created() {
    this.getTemplates()
  },

  methods: {
    clearCamposTemplate() {
      this.template = {
        titulo: '',
        mensagem: '',
        status: true,
        modo_executivo: false,
      }
    },
    editarTemplate(item) {
      this.novoTemplate = false
      axios.get(`api/v1/template_whatsapp/edit/${item.id}`)
        .then(res => {
          this.template = res.data.dados
        })
      this.$refs.modalTemplate.show()
    },
    async getTemplates() {
      this.tableTemplateWhatsApp.items = []
      await axios.get('api/v1/template_whatsapp/')
        .then(res => {
          res.data.dados.map(payload => {
            this.tableTemplateWhatsApp.items.push(payload)
          })
          this.tableTemplateWhatsApp.items.sort((a, b) => new Date(b.created_at && b.updated_at) - new Date(a.created_at && a.updated_at))
          this.tableTemplateWhatsApp.totalRows = this.tableTemplateWhatsApp.items.length
        })
    },
    deleteFornecedor(item) {
      this.$swal({
        title: 'Tem certeza?',
        text: `Deseja deletar o template ${item.id}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, quero deletar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios.delete(`api/v1/template_whatsapp/delete/${item.id}`, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          })
            .then(res => {
              if (res.data) {
                this.$refs.modalTemplate.hide()
                this.getTemplates()
                this.$swal({
                  icon: 'success',
                  title: 'Deletado!',
                  text: 'Template WhatsApp deletado com sucesso!',
                  timer: 1500,
                  showConfirmButton: false,
                })
              }
            })
            .catch(error => {
              if (error.response) {
                this.$swal({
                  title: 'Erro ao deletar Template!',
                  text: error.response.data.mensagem,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
              } else {
                // Something happened in setting up the request that triggered an Error
              }
            })
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
  .vaiOUracha{
    width: 50vw;
    height: 100px;
    overflow-y: auto;
    -webkit-border-horizontal-spacing: 10px;
  }
  .vaiOUracha::-webkit-scrollbar {
    width: 6px;
  }
  .vaiOUracha::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
</style>

<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        class="mt-1"
      >
        Título :
        <b-form-input
          v-model="template.titulo"
          placeholder="Título"
        />
      </b-col>
      <b-col
        cols="12"
        class="mt-1"
      >
        Mensagem :
        <b-form-textarea
          v-model="template.mensagem"
          placeholder="Mensagem"
          rows="4"
          max-rows="8"
        />
      </b-col>
      <b-col
        cols="12"
        class="mt-1 d-flex align-items-center"
      >
        Status:
        <b-form-checkbox
          v-model="template.status"
          class="ml-1"
          checked="true"
          name="check-button"
          switch
          inline
        />
      </b-col>
      <b-col
        cols="12"
        class="mt-1 d-flex align-items-center"
      >
        Executivo:
        <b-form-checkbox
          v-model="template.modo_executivo"
          class="ml-1"
          checked="false"
          name="check-button"
          switch
          inline
        />
      </b-col>
    </b-row>
    <hr>
    <b-row
      align-h="end"
      class="mt-1"
    >
      <b-col cols="auto">
        <b-button
          variant="danger"
          @click="$refs.modalTemplate.hide()"
        >
          Cancelar
        </b-button>
      </b-col>
      <b-col cols="auto">
        <b-button
          variant="primary"
          @click="novoTemplate ? cadastrarTemplate() : updateTemplateWhatsApp()"
        >
          {{ novoTemplate ? 'Cadastrar' : 'Salvar' }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
} from 'bootstrap-vue'
import axios from '@/../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
    novoTemplate: {
      type: Boolean,
      require: true,
    },
  },
  methods: {
    // eslint-disable-next-line consistent-return
    async cadastrarTemplate() {
      if (!this.validaFormulario()) {
        return
      }
      await axios.post('api/v1/template_whatsapp/store', this.template)
        .then(() => {
          this.hideANDgetModal()
          this.$swal({
            title: 'Template cadastrado com sucesso',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(() => {
          this.hideANDgetModal()
          this.$swal({
            title: 'ERRO',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
      this.$emit('clear')
    },
    async updateTemplateWhatsApp() {
      if (!this.validaFormulario()) {
        return
      }
      await axios.put(`api/v1/template_whatsapp/update/${this.template.id}`, this.template)
        .then(() => {
          this.hideANDgetModal()
          this.$swal({
            icon: 'success',
            title: 'Salvo!',
            text: 'Template salvo com sucesso!',
            timer: 1500,
            showConfirmButton: false,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }).catch(error => {
          this.$swal({
            title: 'Erro ao Salvar!',
            text: error,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
    hideANDgetModal() {
      this.$emit('hide')
      this.$emit('templates')
    },
    validaFormulario() {
      if (!(this.template.mensagem && this.template.titulo)) {
        this.$swal({
          title: 'Prencha todos campos!',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        })
        return false
      }
      return true
    },
  },
}
</script>
